import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import language_config from "../../../language-config";

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  ns: ["translations"],
  defaultNS: "translations",

  // To investigate react-i18next translation issues enable this
  debug: false,

  interpolation: {
    escapeValue: false
  },

  keySeparator: true,

  react: {
    wait: true,
    useSuspense: false,
    hashTransKey(defaultValue) {
      return defaultValue;
    }
  }
});

i18n.languages = Object.keys(language_config);

export const localize = (string, values) => {
  return i18n.t(string, { defaultValue: string, ...values });
};
export default i18n;
