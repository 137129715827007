import React from "react";
import { Button } from "reactstrap";
import language_config from "../../../language-config";
import { navigate } from "gatsby";

export const LocalizedButton = ({ to, language, ...props }) => {
  // If it's the default language, don't do anything
  // If it's another language, add the "path"
  const { is_default, path } = language_config[language];
  const path_to = is_default ? to : `/${path}/${to}`;

  const handleRedirect = e => {
    e.preventDefault();
    navigate(path_to);
  };

  return <Button {...props} onClick={handleRedirect} />;
};
