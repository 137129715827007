import React from "react";

import "./assets/css/bootstrap.min.css";
import "./assets/scss/paper-kit.scss";
//import "./assets/demo/demo.css"
//import "./assets/demo/react-demo.css"

import "font-awesome/css/font-awesome.min.css";
import { withTranslation } from "./components/language/withTranslation";

const withUIKit = WrappedComponent => {
  return props => {
    return <WrappedComponent {...props} />;
  };
};

export default withTranslation(withUIKit);
