import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Helmet } from 'react-helmet'

const Footer = () => {
  return (
    <>
      <Helmet>
        <title>Show some citylove – Gutscheine zum freuen, schenken, helfen!</title>
        
        <meta property="generator"    content="Good ol&apos; handmade code" />
        <meta property="page-topic"    content="Show some citylove – Gutscheine zum Freuen, Schenken, Helfen!" />
        <meta property="page-type"    content="Show some citylove – Gutscheine zum Freuen, Schenken, Helfen!" />
        <meta property="description"    content="Show some citylove – Gutscheine zum Freuen, Schenken, Helfen! Mit citylove unterstützt du den regionalen Handel und die Gastronomie während des Ausnahmezustands. Gleichzeitig kaufst du dir und deinen Liebsten schon jetzt Erlebnisse für später." />
        <meta property="keywords"     content="österreich austria tirol tyrol innsbruck" />
        <meta property="audience"    content="everybody" />
        <meta property="author"     content="Network Ventures" />
        <meta property="publisher"     content="Network Ventures" />
        <meta property="copyright"     content="Network Ventures" />
        <meta property="city"      content="Innsbruck" />
        <meta property="country"     content="Austria" />
        <meta property="state"      content="Tyrol" />
        <meta property="zipcode"     content="6020" />
        <meta property="geo.position"    content="47.2601210;11.3956200" />
        <meta property="geo.placename"    content="Innsbruck" />
        <meta property="geo.region"    content="AT-Tirol" />
        <meta property="icbm"      content="47.2601210,11.3956200" />
        <meta property="og:url" content="https://citylove.community" />
        <meta property="og:title" content="Citylove" />
        <meta property="og:description" content="Love your city. Die Corona Krise betrifft uns nicht nur persönlich, auch viele kleinere und größere Unternehmen sind betroffen. Wir geben euch die Möglichkeit diese direkt zu unterstützen." />
        <meta property="og:image" content="https://citylove.community/fb/citylove_fb.jpg" />
    </Helmet>


      <div className="blog-post section landing-section text-center">
        <Container>
          <Row>
            <div className="related-articles" style={{width: "100%", "margin-top": "0px"}}><h3 className="title">Citylove wird unterstützt von</h3><legend></legend>
              <Container>
                <Row>
                  <div className="col-md-2"  style={{flex: "none", "max-width": "none"}}>
                    <a href="https://www.kaufhaus-tyrol.at/"><img  style={{height: "150px", width: "150px"}} alt="..." className="img-rounded img-responsive" src={ require("../../images/partner/KaufhausTyrol.jpg") } /></a>
                    <p className="blog-title">Kaufhaus Tyrol</p>
                  </div>
                </Row>
              </Container>
            </div>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col className="citylove-top ml-auto mr-auto mt-0 mb-2 text-center">
              <a href="#top" className="btn btn-neutral btn-round">
                <span>Top</span>
                <i className="nc-icon nc-minimal-up"></i>
              </a>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto mt-5 mb-4">
              <div
                className="ml-auto mr-auto"
                style={{
                  width: 150,
                  height: 150,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundImage:
                    "url(" +
                    require("../../images/logos/citylove_logo_full.svg") +
                    ")"
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto mt-4 mb-4 text-center">
              <div className="mr-auto ml-auto">
                <span className="copyright">
                  © {new Date().getFullYear()}
                  , made with <i className="fa fa-heart heart" /> by
                </span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto mt-4 mb-5">
              <div
                className="ml-auto mr-auto"
                style={{
                  width: 100,
                  height: 100,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundImage:
                    "url(" +
                    require("../../images/logos/networkventures_logo_full.svg") +
                    ")"
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <hr />
      <div className="social-line">
        <Container>
          <Row>
            <Col md={3} className="d-none d-md-block">
              <h3 className="title">Follow us</h3>
            </Col>
            <Col md={3}>
              {/* <a
                href="#instagram"
                className="btn-link btn-just-icon btn btn-instagram"
              >
                <i className="fa fa-instagram"></i>
                &nbsp;Instagram
              </a> */}
            </Col>
            <Col md={3}>
              {/* <a
                href="#twitter"
                className="btn-link btn-just-icon btn btn-twitter"
              >
                <i className="fa fa-twitter"></i>
                &nbsp;Twitter
              </a> */}
            </Col>
            <Col md={3}>
              <a
                href="https://www.facebook.com/citylove.community"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link btn-just-icon btn btn-facebook"
              >
                <i className="fa fa-facebook"></i>
                &nbsp;Facebook
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Footer;
