import React from "react";
import Headroom from "headroom.js";
import {
  Collapse,
  Container,
  Nav,
  NavbarBrand,
  NavItem,
  Navbar,
  Row,
  Col
} from "reactstrap";
import { LocalizedLink } from "../language/LocalizedLink";
import language_config from "../../../language-config";
import { navigate } from "gatsby";

const CustomNavbar = ({ language }) => {
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  });

  const onLogoClick = () => {
    const { is_default, path } = language_config[language];
    const path_to = is_default ? "/" : `/${path}/`;
    
    navigate(path_to);
  };

  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className="fixed-top" expand="lg" id="navbar-main">
        <div className="navbar-translate">
          <NavbarBrand id="navbar-brand">
            <div
              onClick={onLogoClick}
              className="navbar-logo-heart"
              style={{
                width: 40,
                height: 40,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundImage:
                  "url(" +
                  require("../../images/logos/citylove_heart_red.svg") +
                  ")",
                cursor: "pointer"
              }}
            />
            <Container className="navbar-hashtags">
              <Row>
                <Col className="mb-auto mt-auto">
                  <p className="text-lowercase">#citylove <span className="d-none d-sm-inline">#citylovecommunity</span></p>
                </Col>
              </Row>
            </Container>
          </NavbarBrand>
          <button
            className="navbar-toggler mr-5"
            id="navigation"
            type="button"
            onClick={() => {
              document.documentElement.classList.toggle("nav-open");
              setBodyClick(true);
              setCollapseOpen(true);
            }}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse navbar isOpen={collapseOpen}>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <LocalizedLink
                onClick={() =>
                  document.documentElement.classList.toggle("nav-open")
                }
                className="nav-link"
                language={language}
                to="/"
              >
                Home
              </LocalizedLink>
            </NavItem>
            {/* <NavItem>
              <LocalizedLink
                onClick={() =>
                  document.documentElement.classList.toggle("nav-open")
                }
                className="nav-link"
                language={language}
                to="/how-to"
              >
                So Funktioniert's
              </LocalizedLink>
            </NavItem> */}
            <NavItem>
              <LocalizedLink
                onClick={() =>
                  document.documentElement.classList.toggle("nav-open")
                }
                className="nav-link"
                language={language}
                to="/joinin"
              >
                Partner werden
              </LocalizedLink>
            </NavItem>
            <NavItem>
              <LocalizedLink
                onClick={() =>
                  document.documentElement.classList.toggle("nav-open")
                }
                className="nav-link"
                language={language}
                to="/disclaimer"
              >
                AGB
              </LocalizedLink>
            </NavItem>
            <NavItem>
              <LocalizedLink
                onClick={() =>
                  document.documentElement.classList.toggle("nav-open")
                }
                className="nav-link"
                language={language}
                to="/about"
              >
                Über
              </LocalizedLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </>
  );
};

export default CustomNavbar;
