import React from "react";
import { localize } from "src/components/language/config";
import { LocalizedLink } from "src/components/language/LocalizedLink";
import CookieConsent from "react-cookie-consent";

const CookieWarner = ({ language }) => {

  return (
    <>
        <CookieConsent
            location="bottom"
            buttonText={localize("cookieWarner.button.caption")}
            cookieName="citylove#1"
            style={{ background: "#D64933" }}
            buttonStyle={{ color: "#D64933", background: "#ffffff", fontSize: "14px", fontWeight: "600", textTransform: "uppercase", paddingTop: "3px" , paddingBottom: "3px", paddingLeft: "15px", paddingRight: "15px", borderRadius: "30px" }}
            expires={150}
        >
            {localize("cookieWarner.message")} {" "} 
            
            <span>
            {localize("cookieWarner.moreInfo")} <LocalizedLink
                className="font-weight-bolder text-white"
                language={language}
                to="/about"
              >
                {localize("cookieWarner.moreInfo.link.caption")} 
              </LocalizedLink>
            </span>.
        </CookieConsent>
    </>
  );
};

export default CookieWarner;
