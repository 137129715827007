import React from "react";
import Footer from "./Custom/Footer";
import CookieWarner from "./Custom/CookieWarner.js";


export default (props ) => {

    const getChildrenWithProps = () => {
        return React.Children.map(props.children, (child) => {
            return React.cloneElement(child, {
            lanugage: props.language
            });
        });
    }
    return (
    <>
        <a name="top"></a>
        {getChildrenWithProps()}
        <hr/>
        <Footer />
        <CookieWarner {...props} />
    </>
    )
};