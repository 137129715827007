module.exports = {
  de: {
    is_default: true,
    path: "de",
    display_name: "Deutsch"
  },
  en: {
    path: "en",
    display_name: "English"
  }
}
